:root {
    --primary-color: #79BFA0;
    --secondary-color: #99C0AC;
    --body-background-color: #f6f2f1;
    --header-height: 64px;
    --low-z-index: 0;
    --medium-z-index: 1;
    --high-z-index: 2;
    --footer-height: 98px;
    --scroll-container-height: 70px;
    --scroll-arrow-size: 10px;
    --font-size-header-1: 33;
    --font-size-header-2: 28;
    --font-size-header-3: 26;
    --font-size-header-4: 22;
    --font-size-header-5: 18;
    --default-font-size: 16;
}

.App {
    text-align: center;
    height: 100vh;
    width: 100vw;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
