.arrow-down-container {
    font-size: 15px;
    color: lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--scroll-container-height);
}

.arrow-down {
    margin-top: 5px;
    width: var(--scroll-arrow-size);
    height: var(--scroll-arrow-size);
    border-left: 2px solid lightgrey;
    border-bottom: 2px solid lightgrey;
    transform: rotate(315deg);
    animation: bounceDown 2s infinite;
}

@keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0) rotate(315deg);
    }
    40% {
        transform: translateY(-10px) rotate(315deg);
    }
    60% {
        transform: translateY(-5px) rotate(315deg);
    }
}
