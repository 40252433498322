.welcome-page {
    width: 100%;
    position: absolute;
    z-index: var(--medium-z-index);
}

.page {
    height: 100vh;
    width: 100%;
    scroll-snap-type: y mandatory;
    scroll-snap-align: start;
    text-align: center;
    padding: 15px;
}

.page1 {
    background-color: #f0f8ff;
}

.page2 {
    background-color: #f0f8ff;
}

.page3 {
    background-color: #f0f8ff;
}
