.arrow-up-container {
    margin: 0 auto;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--scroll-container-height);
}

.arrow-up {
    margin-top: 5px;
    width: var(--scroll-arrow-size);
    height: var(--scroll-arrow-size);
    border-left: 2px solid lightgrey;
    border-bottom: 2px solid lightgrey;
    transform: rotate(135deg);
    animation: bounceUp 2s infinite;
}

@keyframes bounceUp {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0) rotate(135deg);
    }
    40% {
        transform: translateY(10px) rotate(135deg);
    }
    60% {
        transform: translateY(5px) rotate(135deg);
    }
}
